import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const Team = () => (
  <Layout>
    <SEO title="Team" />
    <div>
      <h1>Team</h1>
      <p>The people who work on the stuff.</p>
    </div>
  </Layout>
)

export default Team
